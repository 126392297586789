*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  color: hsl(var(--nextui-foreground));
  background-color: hsl(var(--nextui-background));
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #006fee80;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-2 {
  top: .5rem;
  bottom: .5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[10\%\] {
  bottom: 10%;
}

.bottom-\[5\%\] {
  bottom: 5%;
}

.end-0 {
  inset-inline-end: 0;
}

.end-4 {
  inset-inline-end: 1rem;
}

.end-\[env\(safe-area-inset-right\)\] {
  inset-inline-end: env(safe-area-inset-right);
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-3 {
  left: .75rem;
}

.left-\[10\%\] {
  left: 10%;
}

.left-\[5\%\] {
  left: 5%;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-3 {
  right: .75rem;
}

.right-\[10\%\] {
  right: 10%;
}

.right-\[5\%\] {
  right: 5%;
}

.start-4 {
  inset-inline-start: 1rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[5\%\] {
  top: 5%;
}

.top-\[env\(safe-area-inset-top\)\] {
  top: env(safe-area-inset-top);
}

.top-\[var\(--navbar-height\)\] {
  top: var(--navbar-height);
}

.-z-30 {
  z-index: -30;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.-m-2 {
  margin: -.5rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 1rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-\[1px\], .mb-px {
  margin-bottom: 1px;
}

.me-1 {
  margin-inline-end: .25rem;
}

.me-2 {
  margin-inline-end: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ms-1 {
  margin-inline-start: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-auto {
  margin-top: auto;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.\!h-\[100dvh\] {
  height: 100dvh !important;
}

.\!h-auto {
  height: auto !important;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[100dvh\] {
  height: 100dvh;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[calc\(100dvh_-_var\(--navbar-height\)_-_1px\)\] {
  height: calc(100dvh - var(--navbar-height)  - 1px);
}

.h-\[var\(--navbar-height\)\] {
  height: var(--navbar-height);
}

.h-auto {
  height: auto;
}

.h-divider {
  height: var(--nextui-divider-weight);
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.h-unit-10 {
  height: var(--nextui-spacing-unit-10);
}

.h-unit-12 {
  height: var(--nextui-spacing-unit-12);
}

.h-unit-4 {
  height: var(--nextui-spacing-unit-4);
}

.h-unit-8 {
  height: var(--nextui-spacing-unit-8);
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-\[calc\(100\%_-_7\.5rem\)\] {
  max-height: calc(100% - 7.5rem);
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-unit-10 {
  min-height: var(--nextui-spacing-unit-10);
}

.min-h-unit-12 {
  min-height: var(--nextui-spacing-unit-12);
}

.min-h-unit-8 {
  min-height: var(--nextui-spacing-unit-8);
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[calc\(100\%_-_theme\(spacing\.unit-6\)\)\] {
  width: calc(100% - var(--nextui-spacing-unit-6));
}

.w-auto {
  width: auto;
}

.w-divider {
  width: var(--nextui-divider-weight);
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.w-unit-10 {
  width: var(--nextui-spacing-unit-10);
}

.w-unit-12 {
  width: var(--nextui-spacing-unit-12);
}

.w-unit-4 {
  width: var(--nextui-spacing-unit-4);
}

.w-unit-8 {
  width: var(--nextui-spacing-unit-8);
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-unit-10 {
  min-width: var(--nextui-spacing-unit-10);
}

.min-w-unit-12 {
  min-width: var(--nextui-spacing-unit-12);
}

.min-w-unit-16 {
  min-width: var(--nextui-spacing-unit-16);
}

.min-w-unit-20 {
  min-width: var(--nextui-spacing-unit-20);
}

.min-w-unit-24 {
  min-width: var(--nextui-spacing-unit-24);
}

.min-w-unit-5 {
  min-width: var(--nextui-spacing-unit-5);
}

.min-w-unit-6 {
  min-width: var(--nextui-spacing-unit-6);
}

.min-w-unit-7 {
  min-width: var(--nextui-spacing-unit-7);
}

.min-w-unit-8 {
  min-width: var(--nextui-spacing-unit-8);
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-\[1024px\] {
  max-width: 1024px;
}

.max-w-\[1280px\] {
  max-width: 1280px;
}

.max-w-\[1536px\] {
  max-width: 1536px;
}

.max-w-\[640px\] {
  max-width: 640px;
}

.max-w-\[768px\] {
  max-width: 768px;
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.origin-center {
  transform-origin: center;
}

.origin-left {
  transform-origin: 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes drip-expand {
  0% {
    opacity: .2;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-drip-expand {
  animation: .42s linear drip-expand;
}

@keyframes indeterminate-bar {
  0% {
    transform: translateX(-50%)scaleX(.2);
  }

  100% {
    transform: translateX(100%)scaleX(1);
  }
}

.animate-indeterminate-bar {
  animation: 1.5s cubic-bezier(.65, .815, .735, .395) infinite indeterminate-bar;
}

.animate-none {
  animation: none;
}

@keyframes spinner-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spinner-ease-spin {
  animation: .8s infinite spinner-spin;
}

.animate-spinner-linear-spin {
  animation: .8s linear infinite spinner-spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.scroll-py-6 {
  scroll-padding-top: 1.5rem;
  scroll-padding-bottom: 1.5rem;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.\!gap-0 {
  gap: 0 !important;
}

.\!gap-unit-0 {
  gap: var(--nextui-spacing-unit-0) !important;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-unit-2 {
  gap: var(--nextui-spacing-unit-2);
}

.gap-unit-3 {
  gap: var(--nextui-spacing-unit-3);
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\] {
  border-radius: calc(var(--nextui-radius-medium) * .5);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\] {
  border-radius: calc(var(--nextui-radius-medium) * .6);
}

.rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\] {
  border-radius: calc(var(--nextui-radius-medium) * .7);
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-large {
  border-radius: var(--nextui-radius-large);
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-medium {
  border-radius: var(--nextui-radius-medium);
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-small {
  border-radius: var(--nextui-radius-small);
}

.\!rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.\!rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-b-large {
  border-bottom-right-radius: var(--nextui-radius-large);
  border-bottom-left-radius: var(--nextui-radius-large);
}

.rounded-b-medium {
  border-bottom-right-radius: var(--nextui-radius-medium);
  border-bottom-left-radius: var(--nextui-radius-medium);
}

.rounded-b-small {
  border-bottom-right-radius: var(--nextui-radius-small);
  border-bottom-left-radius: var(--nextui-radius-small);
}

.rounded-e-lg {
  border-start-end-radius: .5rem;
  border-end-end-radius: .5rem;
}

.rounded-t-large {
  border-top-left-radius: var(--nextui-radius-large);
  border-top-right-radius: var(--nextui-radius-large);
}

.rounded-t-medium {
  border-top-left-radius: var(--nextui-radius-medium);
  border-top-right-radius: var(--nextui-radius-medium);
}

.rounded-t-small {
  border-top-left-radius: var(--nextui-radius-small);
  border-top-right-radius: var(--nextui-radius-small);
}

.rounded-es-lg {
  border-end-start-radius: .5rem;
}

.rounded-se-lg {
  border-start-end-radius: .5rem;
}

.rounded-se-none {
  border-start-end-radius: 0;
}

.rounded-ss-none {
  border-start-start-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-medium {
  border-width: var(--nextui-border-width-medium);
}

.border-small {
  border-width: var(--nextui-border-width-small);
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-medium {
  border-bottom-width: var(--nextui-border-width-medium);
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dotted {
  border-style: dotted;
}

.border-none {
  border-style: none;
}

.\!border-danger {
  --tw-border-opacity: 1 !important;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity))) !important;
}

.border-background {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-border-opacity)));
}

.border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.border-default {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.border-default-200 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-border-opacity)));
}

.border-default-300 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-border-opacity)));
}

.border-divider {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-divider) / var(--nextui-divider-opacity, var(--tw-border-opacity)));
}

.border-foreground {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.border-transparent {
  border-color: #0000;
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.border-b-current {
  border-bottom-color: currentColor;
}

.border-b-danger {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.border-b-default {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.border-b-secondary {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.border-b-success {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.border-b-warning {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-transparent {
  border-top-color: #0000;
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-bg-opacity)));
}

.bg-background\/10 {
  background-color: hsl(var(--nextui-background) / .1);
}

.bg-background\/70 {
  background-color: hsl(var(--nextui-background) / .7);
}

.bg-background\/80 {
  background-color: hsl(var(--nextui-background) / .8);
}

.bg-content1 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content1) / var(--nextui-content1-opacity, var(--tw-bg-opacity)));
}

.bg-content3 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content3) / var(--nextui-content3-opacity, var(--tw-bg-opacity)));
}

.bg-current {
  background-color: currentColor;
}

.bg-danger {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.bg-danger\/20 {
  background-color: hsl(var(--nextui-danger) / .2);
}

.bg-default {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.bg-default-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.bg-default-300\/50 {
  background-color: hsl(var(--nextui-default-300) / .5);
}

.bg-default-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-bg-opacity)));
}

.bg-default-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-50) / var(--nextui-default-50-opacity, var(--tw-bg-opacity)));
}

.bg-default-500 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-bg-opacity)));
}

.bg-default\/40 {
  background-color: hsl(var(--nextui-default) / .4);
}

.bg-divider {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-divider) / var(--nextui-divider-opacity, var(--tw-bg-opacity)));
}

.bg-foreground {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.bg-foreground\/10 {
  background-color: hsl(var(--nextui-foreground) / .1);
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-overlay\/30 {
  background-color: hsl(var(--nextui-overlay) / .3);
}

.bg-overlay\/50 {
  background-color: hsl(var(--nextui-overlay) / .5);
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.bg-primary-300 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary-300) / var(--nextui-primary-300-opacity, var(--tw-bg-opacity)));
}

.bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary-50) / var(--nextui-primary-50-opacity, var(--tw-bg-opacity)));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary-500) / var(--nextui-primary-500-opacity, var(--tw-bg-opacity)));
}

.bg-primary-500\/25 {
  background-color: hsl(var(--nextui-primary-500) / .25);
}

.bg-primary\/20 {
  background-color: hsl(var(--nextui-primary) / .2);
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.bg-secondary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary-50) / var(--nextui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.bg-secondary\/20 {
  background-color: hsl(var(--nextui-secondary) / .2);
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.bg-success-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.bg-success\/20 {
  background-color: hsl(var(--nextui-success) / .2);
}

.bg-transparent {
  background-color: #0000;
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.bg-warning-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.bg-warning\/20 {
  background-color: hsl(var(--nextui-warning) / .2);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/25 {
  background-color: #ffffff40;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-stripe-gradient {
  background-image: linear-gradient(45deg, #0000001a 25%, #0000 25% 50%, #0000001a 50% 75%, #0000 75%, #0000);
}

.bg-\[length\:1\.25rem_1\.25rem\] {
  background-size: 1.25rem 1.25rem;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-default-300\/50 {
  stroke: hsl(var(--nextui-default-300) / .5);
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.\!px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-unit-0 {
  padding-left: var(--nextui-spacing-unit-0);
  padding-right: var(--nextui-spacing-unit-0);
}

.px-unit-3 {
  padding-left: var(--nextui-spacing-unit-3);
  padding-right: var(--nextui-spacing-unit-3);
}

.px-unit-4 {
  padding-left: var(--nextui-spacing-unit-4);
  padding-right: var(--nextui-spacing-unit-4);
}

.px-unit-6 {
  padding-left: var(--nextui-spacing-unit-6);
  padding-right: var(--nextui-spacing-unit-6);
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-\[env\(safe-area-inset-bottom\)\] {
  padding-bottom: env(safe-area-inset-bottom);
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-\[env\(safe-area-inset-left\)\] {
  padding-left: env(safe-area-inset-left);
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-\[env\(safe-area-inset-right\)\] {
  padding-right: env(safe-area-inset-right);
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-\[env\(safe-area-inset-top\)\] {
  padding-top: env(safe-area-inset-top);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[0\.55rem\] {
  font-size: .55rem;
}

.text-\[0\.5rem\] {
  font-size: .5rem;
}

.text-\[0\.6rem\] {
  font-size: .6rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-large {
  font-size: var(--nextui-font-size-large);
  line-height: var(--nextui-line-height-large);
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-medium {
  font-size: var(--nextui-font-size-medium);
  line-height: var(--nextui-line-height-medium);
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-small {
  font-size: var(--nextui-font-size-small);
  line-height: var(--nextui-line-height-small);
}

.text-tiny {
  font-size: var(--nextui-font-size-tiny);
  line-height: var(--nextui-line-height-tiny);
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-8 {
  line-height: 2rem;
}

.\!text-danger {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity))) !important;
}

.text-background {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-background) / var(--nextui-background-opacity, var(--tw-text-opacity)));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-danger {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.text-danger-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-300) / var(--nextui-danger-300-opacity, var(--tw-text-opacity)));
}

.text-danger-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
}

.text-danger-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.text-default-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-text-opacity)));
}

.text-default-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-text-opacity)));
}

.text-default-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-600) / var(--nextui-default-600-opacity, var(--tw-text-opacity)));
}

.text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.text-foreground-400 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-400) / var(--nextui-foreground-400-opacity, var(--tw-text-opacity)));
}

.text-foreground-500 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-500) / var(--nextui-foreground-500-opacity, var(--tw-text-opacity)));
}

.text-foreground-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-600) / var(--nextui-foreground-600-opacity, var(--tw-text-opacity)));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)));
}

.text-primary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-text-opacity)));
}

.text-secondary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.text-success-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.text-success-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.text-warning-600 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.text-warning-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-\[value\] {
  opacity: value;
}

.opacity-disabled {
  opacity: var(--nextui-disabled-opacity);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_1px_0px_0_rgba\(0\,0\,0\,0\.05\)\] {
  --tw-shadow: 0 1px 0px 0 #0000000d;
  --tw-shadow-colored: 0 1px 0px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-large {
  --tw-shadow: var(--nextui-box-shadow-large);
  --tw-shadow-colored: var(--nextui-box-shadow-large);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-medium {
  --tw-shadow: var(--nextui-box-shadow-medium);
  --tw-shadow-colored: var(--nextui-box-shadow-medium);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-small {
  --tw-shadow: var(--nextui-box-shadow-small);
  --tw-shadow-colored: var(--nextui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-danger\/40 {
  --tw-shadow-color: hsl(var(--nextui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-default\/50 {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-foreground\/40 {
  --tw-shadow-color: hsl(var(--nextui-foreground) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary\/40 {
  --tw-shadow-color: hsl(var(--nextui-primary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-secondary\/40 {
  --tw-shadow-color: hsl(var(--nextui-secondary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-success\/40 {
  --tw-shadow-color: hsl(var(--nextui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-warning\/40 {
  --tw-shadow-color: hsl(var(--nextui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-danger {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-ring-opacity)));
}

.ring-default {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-ring-opacity)));
}

.ring-focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-focus) / var(--nextui-focus-opacity, var(--tw-ring-opacity)));
}

.ring-secondary {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-ring-opacity)));
}

.ring-success {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-ring-opacity)));
}

.ring-warning {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-ring-opacity)));
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-background {
  --tw-ring-offset-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, 1));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg {
  --tw-blur: blur(16px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-opacity-disabled {
  --tw-backdrop-opacity: opacity(var(--nextui-disabled-opacity));
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.\!transition-none {
  transition-property: none !important;
}

.transition-none {
  transition-property: none;
}

.\!duration-150 {
  transition-duration: .15s !important;
}

.\!duration-200 {
  transition-duration: .2s !important;
}

.\!duration-300 {
  transition-duration: .3s !important;
}

.\!duration-500 {
  transition-duration: .5s !important;
}

.duration-150 {
  transition-duration: .15s;
}

.\!ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.\!ease-soft-spring {
  transition-timing-function: cubic-bezier(.155, 1.105, .295, 1.12) !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-auto {
  will-change: auto;
}

.will-change-transform {
  will-change: transform;
}

:root, .light, [data-theme="light"] {
  color-scheme: light;
  --nextui-background: 0 0% 100%;
  --nextui-foreground-50: 0 0% 98%;
  --nextui-foreground-100: 240 5% 96%;
  --nextui-foreground-200: 240 6% 90%;
  --nextui-foreground-300: 240 5% 84%;
  --nextui-foreground-400: 240 5% 65%;
  --nextui-foreground-500: 240 4% 46%;
  --nextui-foreground-600: 240 5% 34%;
  --nextui-foreground-700: 240 5% 26%;
  --nextui-foreground-800: 240 4% 16%;
  --nextui-foreground-900: 240 6% 10%;
  --nextui-foreground: 202 24% 9%;
  --nextui-divider: 0 0% 7%;
  --nextui-divider-opacity: .15;
  --nextui-focus: 212 100% 47%;
  --nextui-overlay: 0 0% 0%;
  --nextui-content1: 0 0% 100%;
  --nextui-content1-foreground: 202 24% 9%;
  --nextui-content2: 240 5% 96%;
  --nextui-content2-foreground: 240 4% 16%;
  --nextui-content3: 240 6% 90%;
  --nextui-content3-foreground: 240 5% 26%;
  --nextui-content4: 240 5% 84%;
  --nextui-content4-foreground: 240 5% 34%;
  --nextui-default-50: 0 0% 98%;
  --nextui-default-100: 240 5% 96%;
  --nextui-default-200: 240 6% 90%;
  --nextui-default-300: 240 5% 84%;
  --nextui-default-400: 240 5% 65%;
  --nextui-default-500: 240 4% 46%;
  --nextui-default-600: 240 5% 34%;
  --nextui-default-700: 240 5% 26%;
  --nextui-default-800: 240 4% 16%;
  --nextui-default-900: 240 6% 10%;
  --nextui-default-foreground: 0 0% 0%;
  --nextui-default: 240 5% 84%;
  --nextui-primary-50: 351 100% 99%;
  --nextui-primary-100: 350 100% 95%;
  --nextui-primary-200: 350 96% 89%;
  --nextui-primary-300: 350 96% 82%;
  --nextui-primary-400: 350 95% 74%;
  --nextui-primary-500: 350 94% 63%;
  --nextui-primary-600: 350 59% 51%;
  --nextui-primary-700: 350 56% 43%;
  --nextui-primary-800: 350 55% 35%;
  --nextui-primary-900: 350 55% 26%;
  --nextui-primary-foreground: 0 0% 100%;
  --nextui-primary: 350 96% 63%;
  --nextui-secondary-50: 270 62% 95%;
  --nextui-secondary-100: 270 59% 89%;
  --nextui-secondary-200: 270 59% 79%;
  --nextui-secondary-300: 270 59% 68%;
  --nextui-secondary-400: 270 59% 58%;
  --nextui-secondary-500: 270 67% 47%;
  --nextui-secondary-600: 270 67% 38%;
  --nextui-secondary-700: 270 67% 28%;
  --nextui-secondary-800: 270 67% 19%;
  --nextui-secondary-900: 270 67% 9%;
  --nextui-secondary-foreground: 0 0% 100%;
  --nextui-secondary: 270 67% 47%;
  --nextui-success-50: 147 64% 95%;
  --nextui-success-100: 146 61% 89%;
  --nextui-success-200: 146 62% 77%;
  --nextui-success-300: 146 63% 66%;
  --nextui-success-400: 146 62% 55%;
  --nextui-success-500: 146 79% 44%;
  --nextui-success-600: 146 80% 35%;
  --nextui-success-700: 146 79% 26%;
  --nextui-success-800: 146 80% 17%;
  --nextui-success-900: 146 78% 9%;
  --nextui-success-foreground: 0 0% 0%;
  --nextui-success: 146 79% 44%;
  --nextui-warning-50: 55 92% 95%;
  --nextui-warning-100: 37 91% 91%;
  --nextui-warning-200: 37 91% 82%;
  --nextui-warning-300: 37 91% 73%;
  --nextui-warning-400: 37 91% 64%;
  --nextui-warning-500: 37 91% 55%;
  --nextui-warning-600: 37 74% 44%;
  --nextui-warning-700: 37 74% 33%;
  --nextui-warning-800: 37 75% 22%;
  --nextui-warning-900: 37 75% 11%;
  --nextui-warning-foreground: 0 0% 0%;
  --nextui-warning: 37 91% 55%;
  --nextui-danger-50: 339 92% 95%;
  --nextui-danger-100: 340 92% 90%;
  --nextui-danger-200: 339 90% 80%;
  --nextui-danger-300: 339 91% 71%;
  --nextui-danger-400: 339 90% 61%;
  --nextui-danger-500: 339 90% 51%;
  --nextui-danger-600: 339 87% 41%;
  --nextui-danger-700: 339 86% 31%;
  --nextui-danger-800: 339 87% 20%;
  --nextui-danger-900: 340 85% 10%;
  --nextui-danger-foreground: 0 0% 100%;
  --nextui-danger: 339 90% 51%;
  --nextui-spacing-unit: 4px;
  --nextui-spacing-unit-0: 0px;
  --nextui-spacing-unit-1: 4px;
  --nextui-spacing-unit-2: 8px;
  --nextui-spacing-unit-3: 12px;
  --nextui-spacing-unit-4: 16px;
  --nextui-spacing-unit-5: 20px;
  --nextui-spacing-unit-6: 24px;
  --nextui-spacing-unit-7: 28px;
  --nextui-spacing-unit-8: 32px;
  --nextui-spacing-unit-9: 36px;
  --nextui-spacing-unit-10: 40px;
  --nextui-spacing-unit-11: 44px;
  --nextui-spacing-unit-12: 48px;
  --nextui-spacing-unit-13: 52px;
  --nextui-spacing-unit-14: 56px;
  --nextui-spacing-unit-15: 60px;
  --nextui-spacing-unit-16: 64px;
  --nextui-spacing-unit-17: 68px;
  --nextui-spacing-unit-18: 72px;
  --nextui-spacing-unit-20: 80px;
  --nextui-spacing-unit-24: 96px;
  --nextui-spacing-unit-28: 112px;
  --nextui-spacing-unit-32: 128px;
  --nextui-spacing-unit-36: 144px;
  --nextui-spacing-unit-40: 160px;
  --nextui-spacing-unit-44: 176px;
  --nextui-spacing-unit-48: 192px;
  --nextui-spacing-unit-52: 208px;
  --nextui-spacing-unit-56: 224px;
  --nextui-spacing-unit-60: 240px;
  --nextui-spacing-unit-64: 256px;
  --nextui-spacing-unit-72: 288px;
  --nextui-spacing-unit-80: 320px;
  --nextui-spacing-unit-96: 384px;
  --nextui-spacing-unit-xs: 8px;
  --nextui-spacing-unit-sm: 12px;
  --nextui-spacing-unit-md: 16px;
  --nextui-spacing-unit-lg: 22px;
  --nextui-spacing-unit-xl: 36px;
  --nextui-spacing-unit-2xl: 48px;
  --nextui-spacing-unit-3xl: 80px;
  --nextui-spacing-unit-4xl: 120px;
  --nextui-spacing-unit-5xl: 224px;
  --nextui-spacing-unit-6xl: 288px;
  --nextui-spacing-unit-7xl: 384px;
  --nextui-spacing-unit-8xl: 512px;
  --nextui-spacing-unit-9xl: 640px;
  --nextui-spacing-unit-3_5: 14px;
  --nextui-disabled-opacity: .5;
  --nextui-divider-weight: 1px;
  --nextui-font-size-tiny: .75rem;
  --nextui-font-size-small: .875rem;
  --nextui-font-size-medium: 1rem;
  --nextui-font-size-large: 1.125rem;
  --nextui-line-height-tiny: 1rem;
  --nextui-line-height-small: 1.25rem;
  --nextui-line-height-medium: 1.5rem;
  --nextui-line-height-large: 1.75rem;
  --nextui-radius-small: 8px;
  --nextui-radius-medium: 12px;
  --nextui-radius-large: 14px;
  --nextui-border-width-small: 1px;
  --nextui-border-width-medium: 2px;
  --nextui-border-width-large: 3px;
  --nextui-box-shadow-small: 0px 0px 5px 0px #00000005, 0px 2px 10px 0px #0000000f, 0px 0px 1px 0px #0000004d;
  --nextui-box-shadow-medium: 0px 0px 15px 0px #00000008, 0px 2px 30px 0px #00000014, 0px 0px 1px 0px #0000004d;
  --nextui-box-shadow-large: 0px 0px 30px 0px #0000000a, 0px 30px 60px 0px #0000001f, 0px 0px 1px 0px #0000004d;
}

.dark, [data-theme="dark"] {
  color-scheme: dark;
  --nextui-background: 0 0% 0%;
  --nextui-foreground-50: 240 6% 10%;
  --nextui-foreground-100: 240 4% 16%;
  --nextui-foreground-200: 240 5% 26%;
  --nextui-foreground-300: 240 5% 34%;
  --nextui-foreground-400: 240 4% 46%;
  --nextui-foreground-500: 240 5% 65%;
  --nextui-foreground-600: 240 5% 84%;
  --nextui-foreground-700: 240 6% 90%;
  --nextui-foreground-800: 240 5% 96%;
  --nextui-foreground-900: 0 0% 98%;
  --nextui-foreground: 210 6% 93%;
  --nextui-focus: 212 100% 47%;
  --nextui-overlay: 0 0% 0%;
  --nextui-divider: 0 0% 100%;
  --nextui-divider-opacity: .15;
  --nextui-content1: 240 6% 10%;
  --nextui-content1-foreground: 0 0% 98%;
  --nextui-content2: 240 4% 16%;
  --nextui-content2-foreground: 240 5% 96%;
  --nextui-content3: 240 5% 26%;
  --nextui-content3-foreground: 240 6% 90%;
  --nextui-content4: 240 5% 34%;
  --nextui-content4-foreground: 240 5% 84%;
  --nextui-default-50: 240 6% 10%;
  --nextui-default-100: 240 4% 16%;
  --nextui-default-200: 240 5% 26%;
  --nextui-default-300: 240 5% 34%;
  --nextui-default-400: 240 4% 46%;
  --nextui-default-500: 240 5% 65%;
  --nextui-default-600: 240 5% 84%;
  --nextui-default-700: 240 6% 90%;
  --nextui-default-800: 240 5% 96%;
  --nextui-default-900: 0 0% 98%;
  --nextui-default-foreground: 0 0% 100%;
  --nextui-default: 240 5% 26%;
  --nextui-primary-50: 351 100% 99%;
  --nextui-primary-100: 350 100% 95%;
  --nextui-primary-200: 350 96% 89%;
  --nextui-primary-300: 350 96% 82%;
  --nextui-primary-400: 350 95% 74%;
  --nextui-primary-500: 350 94% 63%;
  --nextui-primary-600: 350 59% 51%;
  --nextui-primary-700: 350 56% 43%;
  --nextui-primary-800: 350 55% 35%;
  --nextui-primary-900: 350 55% 26%;
  --nextui-primary-foreground: 0 0% 100%;
  --nextui-primary: 350 96% 63%;
  --nextui-secondary-50: 270 67% 9%;
  --nextui-secondary-100: 270 67% 19%;
  --nextui-secondary-200: 270 67% 28%;
  --nextui-secondary-300: 270 67% 38%;
  --nextui-secondary-400: 270 67% 47%;
  --nextui-secondary-500: 270 59% 58%;
  --nextui-secondary-600: 270 59% 68%;
  --nextui-secondary-700: 270 59% 79%;
  --nextui-secondary-800: 270 59% 89%;
  --nextui-secondary-900: 270 62% 95%;
  --nextui-secondary-foreground: 0 0% 100%;
  --nextui-secondary: 270 59% 58%;
  --nextui-success-50: 146 78% 9%;
  --nextui-success-100: 146 80% 17%;
  --nextui-success-200: 146 79% 26%;
  --nextui-success-300: 146 80% 35%;
  --nextui-success-400: 146 79% 44%;
  --nextui-success-500: 146 62% 55%;
  --nextui-success-600: 146 63% 66%;
  --nextui-success-700: 146 62% 77%;
  --nextui-success-800: 146 61% 89%;
  --nextui-success-900: 147 64% 95%;
  --nextui-success-foreground: 0 0% 0%;
  --nextui-success: 146 79% 44%;
  --nextui-warning-50: 37 75% 11%;
  --nextui-warning-100: 37 75% 22%;
  --nextui-warning-200: 37 74% 33%;
  --nextui-warning-300: 37 74% 44%;
  --nextui-warning-400: 37 91% 55%;
  --nextui-warning-500: 37 91% 64%;
  --nextui-warning-600: 37 91% 73%;
  --nextui-warning-700: 37 91% 82%;
  --nextui-warning-800: 37 91% 91%;
  --nextui-warning-900: 55 92% 95%;
  --nextui-warning-foreground: 0 0% 0%;
  --nextui-warning: 37 91% 55%;
  --nextui-danger-50: 340 85% 10%;
  --nextui-danger-100: 339 87% 20%;
  --nextui-danger-200: 339 86% 31%;
  --nextui-danger-300: 339 87% 41%;
  --nextui-danger-400: 339 90% 51%;
  --nextui-danger-500: 339 90% 61%;
  --nextui-danger-600: 339 91% 71%;
  --nextui-danger-700: 339 90% 80%;
  --nextui-danger-800: 340 92% 90%;
  --nextui-danger-900: 339 92% 95%;
  --nextui-danger-foreground: 0 0% 100%;
  --nextui-danger: 339 90% 51%;
  --nextui-spacing-unit: 4px;
  --nextui-spacing-unit-0: 0px;
  --nextui-spacing-unit-1: 4px;
  --nextui-spacing-unit-2: 8px;
  --nextui-spacing-unit-3: 12px;
  --nextui-spacing-unit-4: 16px;
  --nextui-spacing-unit-5: 20px;
  --nextui-spacing-unit-6: 24px;
  --nextui-spacing-unit-7: 28px;
  --nextui-spacing-unit-8: 32px;
  --nextui-spacing-unit-9: 36px;
  --nextui-spacing-unit-10: 40px;
  --nextui-spacing-unit-11: 44px;
  --nextui-spacing-unit-12: 48px;
  --nextui-spacing-unit-13: 52px;
  --nextui-spacing-unit-14: 56px;
  --nextui-spacing-unit-15: 60px;
  --nextui-spacing-unit-16: 64px;
  --nextui-spacing-unit-17: 68px;
  --nextui-spacing-unit-18: 72px;
  --nextui-spacing-unit-20: 80px;
  --nextui-spacing-unit-24: 96px;
  --nextui-spacing-unit-28: 112px;
  --nextui-spacing-unit-32: 128px;
  --nextui-spacing-unit-36: 144px;
  --nextui-spacing-unit-40: 160px;
  --nextui-spacing-unit-44: 176px;
  --nextui-spacing-unit-48: 192px;
  --nextui-spacing-unit-52: 208px;
  --nextui-spacing-unit-56: 224px;
  --nextui-spacing-unit-60: 240px;
  --nextui-spacing-unit-64: 256px;
  --nextui-spacing-unit-72: 288px;
  --nextui-spacing-unit-80: 320px;
  --nextui-spacing-unit-96: 384px;
  --nextui-spacing-unit-xs: 8px;
  --nextui-spacing-unit-sm: 12px;
  --nextui-spacing-unit-md: 16px;
  --nextui-spacing-unit-lg: 22px;
  --nextui-spacing-unit-xl: 36px;
  --nextui-spacing-unit-2xl: 48px;
  --nextui-spacing-unit-3xl: 80px;
  --nextui-spacing-unit-4xl: 120px;
  --nextui-spacing-unit-5xl: 224px;
  --nextui-spacing-unit-6xl: 288px;
  --nextui-spacing-unit-7xl: 384px;
  --nextui-spacing-unit-8xl: 512px;
  --nextui-spacing-unit-9xl: 640px;
  --nextui-spacing-unit-3_5: 14px;
  --nextui-disabled-opacity: .5;
  --nextui-divider-weight: 1px;
  --nextui-font-size-tiny: .75rem;
  --nextui-font-size-small: .875rem;
  --nextui-font-size-medium: 1rem;
  --nextui-font-size-large: 1.125rem;
  --nextui-line-height-tiny: 1rem;
  --nextui-line-height-small: 1.25rem;
  --nextui-line-height-medium: 1.5rem;
  --nextui-line-height-large: 1.75rem;
  --nextui-radius-small: 8px;
  --nextui-radius-medium: 12px;
  --nextui-radius-large: 14px;
  --nextui-border-width-small: 1px;
  --nextui-border-width-medium: 2px;
  --nextui-border-width-large: 3px;
  --nextui-box-shadow-small: 0px 0px 5px 0px #0000000d, 0px 2px 10px 0px #0003, inset 0px 0px 1px 0px #ffffff26;
  --nextui-box-shadow-medium: 0px 0px 15px 0px #0000000f, 0px 2px 30px 0px #00000038, inset 0px 0px 1px 0px #ffffff26;
  --nextui-box-shadow-large: 0px 0px 30px 0px #00000012, 0px 30px 60px 0px #00000042, inset 0px 0px 1px 0px #ffffff26;
}

.leading-inherit {
  line-height: inherit;
}

.bg-img-inherit {
  background-image: inherit;
}

.bg-clip-inherit {
  background-clip: inherit;
}

.text-fill-inherit {
  -webkit-text-fill-color: inherit;
}

.tap-highlight-transparent {
  -webkit-tap-highlight-color: transparent;
}

.transition-all {
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-background {
  transition-property: background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-colors-opacity {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-width {
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-height {
  transition-property: height;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-left {
  transition-property: left;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-opacity {
  transition-property: transform, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-background {
  transition-property: transform, background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.transition-transform-colors {
  transition-property: transform, color, background, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-default {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.scrollbar-default::-webkit-scrollbar {
  display: block;
}

.\[--scale-enter\:100\%\] {
  --scale-enter: 100%;
}

.\[--scale-exit\:100\%\] {
  --scale-exit: 100%;
}

.\[--slide-enter\:0px\] {
  --slide-enter: 0px;
}

.\[--slide-exit\:80px\] {
  --slide-exit: 80px;
}

:root {
  font-family: Inter, system-ui;
}

.placeholder\:text-danger::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-foreground-500::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-500) / var(--nextui-foreground-500-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-primary::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-secondary::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-success-600::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.placeholder\:text-warning-600::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-0:before {
  content: var(--tw-content);
  inset: 0;
}

.before\:z-0:before {
  content: var(--tw-content);
  z-index: 0;
}

.before\:box-border:before {
  content: var(--tw-content);
  box-sizing: border-box;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-0:before {
  content: var(--tw-content);
  height: 0;
}

.before\:h-0\.5:before {
  content: var(--tw-content);
  height: .125rem;
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:w-0:before {
  content: var(--tw-content);
  width: 0;
}

.before\:w-6:before {
  content: var(--tw-content);
  width: 1.5rem;
}

.before\:-translate-x-full:before {
  content: var(--tw-content);
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1:before {
  content: var(--tw-content);
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-0:before {
  content: var(--tw-content);
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes shimmer {
  100% {
    content: var(--tw-content);
    transform: translateX(100%);
  }
}

.before\:animate-\[shimmer_2s_infinite\]:before {
  content: var(--tw-content);
  animation: 2s infinite shimmer;
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .5);
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .6);
}

.before\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\]:before {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .7);
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:rounded-none:before {
  content: var(--tw-content);
  border-radius: 0;
}

.before\:border-2:before {
  content: var(--tw-content);
  border-width: 2px;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-content4\/30:before {
  content: var(--tw-content);
  border-color: hsl(var(--nextui-content4) / .3);
}

.before\:border-default:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.before\:bg-current:before {
  content: var(--tw-content);
  background-color: currentColor;
}

.before\:bg-danger\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-danger) / .2);
}

.before\:bg-default\/40:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-default) / .4);
}

.before\:bg-foreground:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.before\:bg-primary\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-primary) / .2);
}

.before\:bg-secondary\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-secondary) / .2);
}

.before\:bg-success\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-success) / .2);
}

.before\:bg-warning\/20:before {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-warning) / .2);
}

.before\:bg-gradient-to-r:before {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.before\:from-transparent:before {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:via-content4:before {
  content: var(--tw-content);
  --tw-gradient-to: hsl(var(--nextui-content4) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--nextui-content4) / var(--nextui-content4-opacity, 1)) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.before\:to-transparent:before {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.before\:transition-none:before {
  content: var(--tw-content);
  transition-property: none;
}

.before\:\!duration-300:before {
  content: var(--tw-content);
  transition-duration: .3s !important;
}

.before\:duration-150:before {
  content: var(--tw-content);
  transition-duration: .15s;
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:transition-background:before {
  content: var(--tw-content);
  transition-property: background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.before\:transition-opacity:before {
  content: var(--tw-content);
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.before\:transition-width:before {
  content: var(--tw-content);
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.before\:transition-transform:before {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-0:after {
  content: var(--tw-content);
  inset: 0;
}

.after\:-bottom-1:after {
  content: var(--tw-content);
  bottom: -.25rem;
}

.after\:-bottom-\[2px\]:after {
  content: var(--tw-content);
  bottom: -2px;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:left-1\/2:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:-z-10:after {
  content: var(--tw-content);
  z-index: -10;
}

.after\:ml-0:after {
  content: var(--tw-content);
  margin-left: 0;
}

.after\:ml-0\.5:after {
  content: var(--tw-content);
  margin-left: .125rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-\[2px\]:after {
  content: var(--tw-content);
  height: 2px;
}

.after\:h-divider:after {
  content: var(--tw-content);
  height: var(--nextui-divider-weight);
}

.after\:h-full:after {
  content: var(--tw-content);
  height: 100%;
}

.after\:h-px:after {
  content: var(--tw-content);
  height: 1px;
}

.after\:w-0:after {
  content: var(--tw-content);
  width: 0;
}

.after\:w-6:after {
  content: var(--tw-content);
  width: 1.5rem;
}

.after\:w-\[80\%\]:after {
  content: var(--tw-content);
  width: 80%;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:origin-center:after {
  content: var(--tw-content);
  transform-origin: center;
}

.after\:-translate-x-1\/2:after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:translate-y-1:after {
  content: var(--tw-content);
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-0:after {
  content: var(--tw-content);
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:scale-50:after {
  content: var(--tw-content);
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.5\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .5);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.6\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .6);
}

.after\:rounded-\[calc\(theme\(borderRadius\.medium\)\*0\.7\)\]:after {
  content: var(--tw-content);
  border-radius: calc(var(--nextui-radius-medium) * .7);
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:rounded-none:after {
  content: var(--tw-content);
  border-radius: 0;
}

.after\:rounded-xl:after {
  content: var(--tw-content);
  border-radius: .75rem;
}

.after\:bg-content3:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content3) / var(--nextui-content3-opacity, var(--tw-bg-opacity)));
}

.after\:bg-current:after {
  content: var(--tw-content);
  background-color: currentColor;
}

.after\:bg-danger:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.after\:bg-default:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.after\:bg-divider:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-divider) / var(--nextui-divider-opacity, var(--tw-bg-opacity)));
}

.after\:bg-foreground:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.after\:bg-primary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.after\:bg-secondary:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.after\:bg-success:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.after\:bg-warning:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.after\:text-danger:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.after\:text-danger-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-default-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-primary-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-secondary-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-success-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.after\:text-warning-foreground:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.after\:opacity-0:after {
  content: var(--tw-content);
  opacity: 0;
}

.after\:opacity-100:after {
  content: var(--tw-content);
  opacity: 1;
}

.after\:shadow-\[0_1px_0px_0_rgba\(0\,0\,0\,0\.05\)\]:after {
  content: var(--tw-content);
  --tw-shadow: 0 1px 0px 0 #0000000d;
  --tw-shadow-colored: 0 1px 0px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:transition-none:after {
  content: var(--tw-content);
  transition-property: none;
}

.after\:\!duration-200:after {
  content: var(--tw-content);
  transition-duration: .2s !important;
}

.after\:duration-150:after {
  content: var(--tw-content);
  transition-duration: .15s;
}

.after\:\!ease-linear:after {
  content: var(--tw-content);
  transition-timing-function: linear !important;
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\'\*\'\]:after {
  --tw-content: "*";
  content: var(--tw-content);
}

.after\:transition-background:after {
  content: var(--tw-content);
  transition-property: background;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-width:after {
  content: var(--tw-content);
  transition-property: width;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-transform:after {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.after\:transition-transform-opacity:after {
  content: var(--tw-content);
  transition-property: transform, opacity;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.first\:rounded-l-full:first-child {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.first\:rounded-l-large:first-child {
  border-top-left-radius: var(--nextui-radius-large);
  border-bottom-left-radius: var(--nextui-radius-large);
}

.first\:rounded-l-lg:first-child {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.first\:rounded-l-medium:first-child {
  border-top-left-radius: var(--nextui-radius-medium);
  border-bottom-left-radius: var(--nextui-radius-medium);
}

.first\:rounded-l-small:first-child {
  border-top-left-radius: var(--nextui-radius-small);
  border-bottom-left-radius: var(--nextui-radius-small);
}

.first\:before\:rounded-l-lg:first-child:before {
  content: var(--tw-content);
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.last\:rounded-r-full:last-child {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.last\:rounded-r-large:last-child {
  border-top-right-radius: var(--nextui-radius-large);
  border-bottom-right-radius: var(--nextui-radius-large);
}

.last\:rounded-r-lg:last-child {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.last\:rounded-r-medium:last-child {
  border-top-right-radius: var(--nextui-radius-medium);
  border-bottom-right-radius: var(--nextui-radius-medium);
}

.last\:rounded-r-small:last-child {
  border-top-right-radius: var(--nextui-radius-small);
  border-bottom-right-radius: var(--nextui-radius-small);
}

.last\:before\:rounded-r-lg:last-child:before {
  content: var(--tw-content);
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.first-of-type\:rounded-r-none:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.last-of-type\:rounded-l-none:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.focus-within\:border-danger:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-primary:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-secondary:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-success:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.focus-within\:border-warning:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-default:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.hover\:border-default-300:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-border-opacity)));
}

.hover\:\!bg-danger:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:\!bg-default:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:\!bg-foreground:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:\!bg-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:\!bg-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:\!bg-success:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:\!bg-warning:hover {
  --tw-bg-opacity: 1 !important;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity))) !important;
}

.hover\:bg-default-100:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-primary-600:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary-600) / var(--nextui-primary-600-opacity, var(--tw-bg-opacity)));
}

.hover\:\!text-danger-foreground:hover {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity))) !important;
}

.hover\:\!text-primary-foreground:hover {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity))) !important;
}

.hover\:\!text-success-foreground:hover {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity))) !important;
}

.hover\:\!text-warning-foreground:hover {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity))) !important;
}

.hover\:text-secondary-foreground:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:\!opacity-100:hover {
  opacity: 1 !important;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:after\:bg-danger\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-danger) / .2);
}

.hover\:after\:bg-foreground\/10:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-foreground) / .1);
}

.hover\:after\:bg-primary\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-primary) / .2);
}

.hover\:after\:bg-secondary\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-secondary) / .2);
}

.hover\:after\:bg-success\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-success) / .2);
}

.hover\:after\:bg-warning\/20:hover:after {
  content: var(--tw-content);
  background-color: hsl(var(--nextui-warning) / .2);
}

.hover\:after\:opacity-100:hover:after {
  content: var(--tw-content);
  opacity: 1;
}

.focus\:underline:focus {
  text-decoration-line: underline;
}

.focus-visible\:z-10:focus-visible {
  z-index: 10;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-focus:focus-visible {
  outline-color: hsl(var(--nextui-focus) / var(--nextui-focus-opacity, 1));
}

.active\:bg-default-200:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.active\:bg-default-300:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-bg-opacity)));
}

.active\:underline:active {
  text-decoration-line: underline;
}

.active\:\!opacity-70:active {
  opacity: .7 !important;
}

.active\:opacity-disabled:active {
  opacity: var(--nextui-disabled-opacity);
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:border-current {
  border-color: currentColor;
}

.group:hover .group-hover\:text-current {
  color: currentColor;
}

.group.is-splitted .group-\[\.is-splitted\]\:rounded-medium {
  border-radius: var(--nextui-radius-medium);
}

.group.is-splitted .group-\[\.is-splitted\]\:bg-content1 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content1) / var(--nextui-content1-opacity, var(--tw-bg-opacity)));
}

.group.is-splitted .group-\[\.is-splitted\]\:px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.group.is-splitted .group-\[\.is-splitted\]\:shadow-medium {
  --tw-shadow: var(--nextui-box-shadow-medium);
  --tw-shadow-colored: var(--nextui-box-shadow-medium);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.aria-expanded\:scale-\[0\.97\][aria-expanded="true"] {
  --tw-scale-x: .97;
  --tw-scale-y: .97;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.aria-expanded\:opacity-70[aria-expanded="true"] {
  opacity: .7;
}

.data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"] {
  pointer-events: none;
}

.data-\[placement\=bottom-end\]\:-top-1[data-placement="bottom-end"], .data-\[placement\=bottom-start\]\:-top-1[data-placement="bottom-start"], .data-\[placement\=bottom\]\:-top-1[data-placement="bottom"] {
  top: -.25rem;
}

.data-\[placement\=left-end\]\:-right-1[data-placement="left-end"], .data-\[placement\=left-start\]\:-right-1[data-placement="left-start"], .data-\[placement\=left\]\:-right-1[data-placement="left"] {
  right: -.25rem;
}

.data-\[placement\=right-end\]\:-left-1[data-placement="right-end"], .data-\[placement\=right-start\]\:-left-1[data-placement="right-start"], .data-\[placement\=right\]\:-left-1[data-placement="right"] {
  left: -.25rem;
}

.data-\[placement\=top-end\]\:-bottom-1[data-placement="top-end"], .data-\[placement\=top-start\]\:-bottom-1[data-placement="top-start"], .data-\[placement\=top\]\:-bottom-1[data-placement="top"] {
  bottom: -.25rem;
}

.data-\[focus-visible\=true\]\:z-10[data-focus-visible="true"] {
  z-index: 10;
}

.data-\[open\=true\]\:block[data-open="true"] {
  display: block;
}

.data-\[open\=true\]\:flex[data-open="true"] {
  display: flex;
}

.data-\[justify\=end\]\:flex-grow[data-justify="end"], .data-\[justify\=start\]\:flex-grow[data-justify="start"] {
  flex-grow: 1;
}

.data-\[justify\=end\]\:basis-0[data-justify="end"], .data-\[justify\=start\]\:basis-0[data-justify="start"] {
  flex-basis: 0;
}

.data-\[focus-visible\=true\]\:-translate-x-3[data-focus-visible="true"], .data-\[hover\=true\]\:-translate-x-3[data-hover="true"] {
  --tw-translate-x: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[hover\=true\]\:translate-x-0[data-hover="true"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=bottom-end\]\:translate-x-6[data-placement="bottom-end"] {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=bottom-start\]\:-translate-x-8[data-placement="bottom-start"] {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=bottom\]\:-translate-x-1\/2[data-placement="bottom"] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=left-end\]\:translate-y-0\.5[data-placement="left-end"] {
  --tw-translate-y: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=left-start\]\:-translate-y-3[data-placement="left-start"] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=left\]\:-translate-y-1\/2[data-placement="left"] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=right-end\]\:translate-y-0\.5[data-placement="right-end"] {
  --tw-translate-y: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=right-start\]\:-translate-y-3[data-placement="right-start"] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=right\]\:-translate-y-1\/2[data-placement="right"] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=top-end\]\:translate-x-6[data-placement="top-end"] {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=top-start\]\:-translate-x-8[data-placement="top-start"] {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[placement\=top\]\:-translate-x-1\/2[data-placement="top"] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[before\=true\]\:rotate-180[data-before="true"], .data-\[direction\=ascending\]\:rotate-180[data-direction="ascending"] {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[open\=true\]\:-rotate-90[data-open="true"] {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[open\=true\]\:rotate-180[data-open="true"] {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[invisible\=true\]\:scale-0[data-invisible="true"] {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[pressed\=true\]\:scale-\[0\.97\][data-pressed="true"] {
  --tw-scale-x: .97;
  --tw-scale-y: .97;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[disabled\=true\]\:cursor-not-allowed[data-disabled="true"] {
  cursor: not-allowed;
}

.data-\[sortable\=true\]\:cursor-pointer[data-sortable="true"] {
  cursor: pointer;
}

.data-\[orientation\=horizontal\]\:flex-row[data-orientation="horizontal"] {
  flex-direction: row;
}

.data-\[has-helper\=true\]\:items-start[data-has-helper="true"] {
  align-items: flex-start;
}

.data-\[justify\=start\]\:justify-start[data-justify="start"] {
  justify-content: flex-start;
}

.data-\[justify\=end\]\:justify-end[data-justify="end"] {
  justify-content: flex-end;
}

.data-\[justify\=center\]\:justify-center[data-justify="center"] {
  justify-content: center;
}

.data-\[menu-open\=true\]\:border-none[data-menu-open="true"] {
  border-style: none;
}

.data-\[active\=true\]\:border-danger[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-default-400[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-primary[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-secondary[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-success[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:border-warning[data-active="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-danger[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-foreground[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-primary[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-secondary[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-success[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[focus\=true\]\:border-warning[data-focus="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-danger[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-default[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-default-400[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-primary[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-secondary[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-success[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[hover\=true\]\:border-warning[data-hover="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-danger[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-foreground[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-primary[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-secondary[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-success[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[open\=true\]\:border-warning[data-open="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[selected\=true\]\:border-danger[data-selected="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[active\=true\]\:bg-danger[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-default-400[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-primary[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-secondary[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-success[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[active\=true\]\:bg-warning[data-active="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-content2[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-100) / var(--nextui-danger-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-danger\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-danger) / .2);
}

.data-\[hover\=true\]\:bg-default[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default-200[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-default\/40[data-hover="true"] {
  background-color: hsl(var(--nextui-default) / .4);
}

.data-\[hover\=true\]\:bg-primary[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-primary-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary-100) / var(--nextui-primary-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-primary\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-primary) / .2);
}

.data-\[hover\=true\]\:bg-secondary[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-secondary-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary-100) / var(--nextui-secondary-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-secondary\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-secondary) / .2);
}

.data-\[hover\=true\]\:bg-success[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-100) / var(--nextui-success-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-success\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-success) / .2);
}

.data-\[hover\=true\]\:bg-transparent[data-hover="true"] {
  background-color: #0000;
}

.data-\[hover\=true\]\:bg-warning[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning-100[data-hover="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-100) / var(--nextui-warning-100-opacity, var(--tw-bg-opacity)));
}

.data-\[hover\=true\]\:bg-warning\/20[data-hover="true"] {
  background-color: hsl(var(--nextui-warning) / .2);
}

.data-\[loaded\=true\]\:bg-transparent[data-loaded="true"] {
  background-color: #0000;
}

.data-\[selected\=true\]\:bg-danger[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-default[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-primary[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-secondary[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-success[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:bg-warning[data-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[has-helper\=true\]\:pb-4[data-has-helper="true"] {
  padding-bottom: 1rem;
}

.data-\[has-title\=true\]\:pt-1[data-has-title="true"] {
  padding-top: .25rem;
}

.data-\[active\=true\]\:font-semibold[data-active="true"] {
  font-weight: 600;
}

.data-\[active\=true\]\:text-danger-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-default-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-primary-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-secondary-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-success-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[active\=true\]\:text-warning-foreground[data-active="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[disabled\=true\]\:text-default-300[data-disabled="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-300) / var(--nextui-default-300-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-danger[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-danger-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-default-500[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-default-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-foreground-400[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-400) / var(--nextui-foreground-400-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-primary[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-primary-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-secondary[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-secondary-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-success-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[hover\=true\]\:text-warning-foreground[data-hover="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-danger[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-danger-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-default-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-primary[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-primary-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-secondary[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-secondary-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-success-600[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-600) / var(--nextui-success-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-success-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-warning-600[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-600) / var(--nextui-warning-600-opacity, var(--tw-text-opacity)));
}

.data-\[selected\=true\]\:text-warning-foreground[data-selected="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[disabled\=true\]\:opacity-30[data-disabled="true"] {
  opacity: .3;
}

.data-\[hover-unselected\=true\]\:opacity-disabled[data-hover-unselected="true"] {
  opacity: var(--nextui-disabled-opacity);
}

.data-\[invisible\=true\]\:opacity-0[data-invisible="true"] {
  opacity: 0;
}

.data-\[loaded\=true\]\:opacity-100[data-loaded="true"] {
  opacity: 1;
}

.data-\[pressed\=true\]\:opacity-70[data-pressed="true"] {
  opacity: .7;
}

.data-\[visible\=true\]\:opacity-100[data-visible="true"] {
  opacity: 1;
}

.data-\[active\=true\]\:shadow-md[data-active="true"] {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[hover\=true\]\:shadow-lg[data-hover="true"] {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.data-\[active\=true\]\:shadow-danger\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-danger) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-default\/50[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-primary\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-primary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-secondary\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-secondary) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-success\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-success) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[active\=true\]\:shadow-warning\/40[data-active="true"] {
  --tw-shadow-color: hsl(var(--nextui-warning) / .4);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-danger\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-danger) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-default\/50[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-primary\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-primary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-secondary\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-secondary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-success\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-success) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[hover\=true\]\:shadow-warning\/30[data-hover="true"] {
  --tw-shadow-color: hsl(var(--nextui-warning) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[focus-visible\=true\]\:outline-2[data-focus-visible="true"] {
  outline-width: 2px;
}

.data-\[focus-visible\=true\]\:outline-offset-2[data-focus-visible="true"] {
  outline-offset: 2px;
}

.data-\[focus-visible\=true\]\:outline-focus[data-focus-visible="true"] {
  outline-color: hsl(var(--nextui-focus) / var(--nextui-focus-opacity, 1));
}

.data-\[focus-visible\]\:outline-danger-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-default-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-primary-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-secondary-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-success-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, 1));
}

.data-\[focus-visible\]\:outline-warning-foreground[data-focus-visible] {
  outline-color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, 1));
}

.data-\[menu-open\=true\]\:backdrop-blur-xl[data-menu-open="true"] {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.data-\[sortable\=true\]\:transition-colors[data-sortable="true"] {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .25s;
  transition-timing-function: ease;
}

.data-\[bottom-scroll\=true\]\:\[mask-image\:linear-gradient\(180deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-bottom-scroll="true"] {
  -webkit-mask-image: linear-gradient(180deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(180deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[left-right-scroll\=true\]\:\[mask-image\:linear-gradient\(to_right\,\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-left-right-scroll="true"] {
  -webkit-mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[left-scroll\=true\]\:\[mask-image\:linear-gradient\(270deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-left-scroll="true"] {
  -webkit-mask-image: linear-gradient(270deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(270deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[right-scroll\=true\]\:\[mask-image\:linear-gradient\(90deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-right-scroll="true"] {
  -webkit-mask-image: linear-gradient(90deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(90deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[top-bottom-scroll\=true\]\:\[mask-image\:linear-gradient\(\#000\,\#000\,transparent_0\,\#000_var\(--scroll-shadow-size\)\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-top-bottom-scroll="true"] {
  -webkit-mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(#000, #000, transparent 0, #000 var(--scroll-shadow-size), #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[top-scroll\=true\]\:\[mask-image\:linear-gradient\(0deg\,\#000_calc\(100\%_-_var\(--scroll-shadow-size\)\)\,transparent\)\][data-top-scroll="true"] {
  -webkit-mask-image: linear-gradient(0deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
  mask-image: linear-gradient(0deg, #000 calc(100% - var(--scroll-shadow-size)), transparent);
}

.data-\[loaded\=true\]\:before\:animate-none[data-loaded="true"]:before {
  content: var(--tw-content);
  animation: none;
}

.data-\[loaded\=true\]\:before\:opacity-0[data-loaded="true"]:before {
  content: var(--tw-content);
  opacity: 0;
}

.data-\[selected\=true\]\:before\:opacity-100[data-selected="true"]:before {
  content: var(--tw-content);
  opacity: 1;
}

.data-\[focus\=true\]\:after\:w-full[data-focus="true"]:after, .data-\[open\=true\]\:after\:w-full[data-open="true"]:after {
  content: var(--tw-content);
  width: 100%;
}

.data-\[selected\=true\]\:after\:bg-danger[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-foreground[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-primary[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-secondary[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-success[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selected\=true\]\:after\:bg-warning[data-selected="true"]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[loaded\=true\]\:after\:opacity-0[data-loaded="true"]:after {
  content: var(--tw-content);
  opacity: 0;
}

.data-\[selected\=true\]\:after\:opacity-100[data-selected="true"]:after {
  content: var(--tw-content);
  opacity: 1;
}

.data-\[selectable\=true\]\:focus\:border-danger:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-default:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-primary:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-secondary:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-success:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:border-warning:focus[data-selectable="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-danger:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-danger\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-danger) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-default:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-default-100:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-default\/40:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-default) / .4);
}

.data-\[selectable\=true\]\:focus\:bg-primary:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-primary\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-primary) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-secondary:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-secondary\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-secondary) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-success:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-success\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-success) / .2);
}

.data-\[selectable\=true\]\:focus\:bg-warning:focus[data-selectable="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.data-\[selectable\=true\]\:focus\:bg-warning\/20:focus[data-selectable="true"] {
  background-color: hsl(var(--nextui-warning) / .2);
}

.data-\[selectable\=true\]\:focus\:text-danger:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-danger-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-default-500:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-default-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-primary:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-primary-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-secondary:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-secondary-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-success:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-success-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-warning:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:text-warning-foreground:focus[data-selectable="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.data-\[selectable\=true\]\:focus\:shadow-danger\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-danger) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-default\/50:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-default) / .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-primary\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-primary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-secondary\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-secondary) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-success\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-success) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[selectable\=true\]\:focus\:shadow-warning\/30:focus[data-selectable="true"] {
  --tw-shadow-color: hsl(var(--nextui-warning) / .3);
  --tw-shadow: var(--tw-shadow-colored);
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:pointer-events-auto, .group[data-filled="true"] .group-data-\[filled\=true\]\:pointer-events-auto {
  pointer-events: auto;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:left-0, .group[data-filled="true"] .group-data-\[filled\=true\]\:left-0 {
  left: 0;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:z-10 {
  z-index: 10;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ml-4 {
  margin-left: 1rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ml-5 {
  margin-left: 1.25rem;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:ml-6 {
  margin-left: 1.5rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-3 {
  margin-left: .75rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-4 {
  margin-left: 1rem;
}

.group[data-selected][data-pressed] .group-data-\[selected\]\:group-data-\[pressed\]\:ml-5 {
  margin-left: 1.25rem;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:block {
  display: block;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:hidden {
  display: none;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-5 {
  width: 1.25rem;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-6 {
  width: 1.5rem;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:w-7 {
  width: 1.75rem;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_20px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 20px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_24px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 24px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.tiny\)\/2_\+_16px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-tiny) / 2 + 16px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_4px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 4px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_3px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 3px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_20px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 20px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.small\)\/2_\+_24px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-small) / 2 + 24px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(100\%_\+_theme\(fontSize\.tiny\)\/2_\+_16px\)\] {
  --tw-translate-y: calc(calc(100% + var(--nextui-font-size-tiny) / 2 + 16px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_4px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 4px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.small\)\/2_-_5px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-small) / 2 - 5px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:-translate-y-\[calc\(50\%_\+_theme\(fontSize\.tiny\)\/2_-_3px\)\] {
  --tw-translate-y: calc(calc(50% + var(--nextui-font-size-tiny) / 2 - 3px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:translate-x-3 {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-foreground {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-danger {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-default-500 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-default-500) / var(--nextui-default-500-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-border-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:border-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-border-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-danger-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger-50) / var(--nextui-danger-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary-50) / var(--nextui-primary-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-secondary-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary-50) / var(--nextui-secondary-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-success-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success-50) / var(--nextui-success-50-opacity, var(--tw-bg-opacity)));
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:bg-warning-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning-50) / var(--nextui-warning-50-opacity, var(--tw-bg-opacity)));
}

.group[data-hover-unselected="true"] .group-data-\[hover-unselected\=true\]\:bg-default-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-danger {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-default-400 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-400) / var(--nextui-default-400-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-bg-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:bg-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-bg-opacity)));
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-2 {
  padding-top: .5rem;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-3 {
  padding-top: .75rem;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:pt-4 {
  padding-top: 1rem;
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:text-small {
  font-size: var(--nextui-font-size-small);
  line-height: var(--nextui-line-height-small);
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:text-tiny {
  font-size: var(--nextui-font-size-tiny);
  line-height: var(--nextui-line-height-tiny);
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:text-small {
  font-size: var(--nextui-font-size-small);
  line-height: var(--nextui-line-height-small);
}

.group[data-filled="true"] .group-data-\[filled\=true\]\:text-tiny {
  font-size: var(--nextui-font-size-tiny);
  line-height: var(--nextui-line-height-tiny);
}

.group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:font-medium, .group[data-filled="true"] .group-data-\[filled\=true\]\:font-medium {
  font-weight: 500;
}

.group[data-disabled="true"] .group-data-\[disabled\=true\]\:text-foreground-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground-300) / var(--nextui-foreground-300-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-danger {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger) / var(--nextui-danger-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-danger-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-danger-foreground) / var(--nextui-danger-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-default-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-default-foreground) / var(--nextui-default-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-foreground) / var(--nextui-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-primary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-primary-foreground) / var(--nextui-primary-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary) / var(--nextui-secondary-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-secondary-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-secondary-foreground) / var(--nextui-secondary-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-success-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-success-foreground) / var(--nextui-success-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:text-warning-foreground {
  --tw-text-opacity: 1;
  color: hsl(var(--nextui-warning-foreground) / var(--nextui-warning-foreground-opacity, var(--tw-text-opacity)));
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:opacity-0 {
  opacity: 0;
}

.group[data-copied="true"] .group-data-\[copied\=true\]\:opacity-100, .group[data-filled-within="true"] .group-data-\[filled-within\=true\]\:opacity-100, .group[data-filled="true"] .group-data-\[filled\=true\]\:opacity-100, .group[data-hover="true"] .group-data-\[hover\=true\]\:opacity-100, .group[data-loaded="true"] .group-data-\[loaded\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-pressed="true"] .group-data-\[pressed\=true\]\:opacity-70 {
  opacity: .7;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-0 {
  opacity: 0;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-100 {
  opacity: 1;
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:opacity-60 {
  opacity: .6;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(var(--nextui-focus) / var(--nextui-focus-opacity, var(--tw-ring-opacity)));
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.group[data-focus-visible="true"] .group-data-\[focus-visible\=true\]\:ring-offset-background {
  --tw-ring-offset-color: hsl(var(--nextui-background) / var(--nextui-background-opacity, 1));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.group[data-open="true"] .group-data-\[open\=true\]\:before\:translate-y-px:before {
  content: var(--tw-content);
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-open="true"] .group-data-\[open\=true\]\:before\:rotate-45:before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-middle="true"] .group-data-\[middle\=true\]\:before\:rounded-none:before {
  content: var(--tw-content);
  border-radius: 0;
}

.group[data-hover="true"] .group-data-\[hover\=true\]\:before\:bg-default-100:before, .group[data-odd="true"] .group-data-\[odd\=true\]\:before\:bg-default-100:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[data-odd="true"] .group-data-\[odd\=true\]\:before\:opacity-100:before {
  content: var(--tw-content);
  opacity: 1;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.group[data-open="true"] .group-data-\[open\=true\]\:after\:translate-y-0:after {
  content: var(--tw-content);
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-open="true"] .group-data-\[open\=true\]\:after\:-rotate-45:after {
  content: var(--tw-content);
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:after\:scale-100:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-selected="true"] .group-data-\[selected\=true\]\:after\:opacity-100:after {
  content: var(--tw-content);
  opacity: 1;
}

.group[data-first="true"] .group-data-\[first\=true\]\:first\:before\:rounded-tl-lg:first-child:before {
  content: var(--tw-content);
  border-top-left-radius: .5rem;
}

.group[data-last="true"] .group-data-\[last\=true\]\:first\:before\:rounded-bl-lg:first-child:before {
  content: var(--tw-content);
  border-bottom-left-radius: .5rem;
}

.group[data-first="true"] .group-data-\[first\=true\]\:last\:before\:rounded-tr-lg:last-child:before {
  content: var(--tw-content);
  border-top-right-radius: .5rem;
}

.group[data-last="true"] .group-data-\[last\=true\]\:last\:before\:rounded-br-lg:last-child:before {
  content: var(--tw-content);
  border-bottom-right-radius: .5rem;
}

.group[aria-selected="false"][data-hover="true"] .group-aria-\[selected\=false\]\:group-data-\[hover\=true\]\:before\:bg-default-100:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)));
}

.group[aria-selected="false"][data-hover="true"] .group-aria-\[selected\=false\]\:group-data-\[hover\=true\]\:before\:opacity-70:before {
  content: var(--tw-content);
  opacity: .7;
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:block {
  display: block;
}

.peer[data-filled="true"] ~ .peer-data-\[filled\=true\]\:opacity-70 {
  opacity: .7;
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:transition-none {
    transition-property: none;
  }

  .motion-reduce\:after\:transition-none:after {
    content: var(--tw-content);
    transition-property: none;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-background\/20 {
    background-color: hsl(var(--nextui-background) / .2);
  }

  .dark\:bg-content2 {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
  }

  .dark\:bg-default {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--nextui-default) / var(--nextui-default-opacity, var(--tw-bg-opacity)));
  }

  .dark\:bg-transparent {
    background-color: #0000;
  }

  .dark\:text-danger-500 {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
  }

  .dark\:text-success {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
  }

  .dark\:text-warning {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
  }

  .dark\:placeholder\:text-danger-500::placeholder {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
  }

  .dark\:placeholder\:text-success::placeholder {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
  }

  .dark\:placeholder\:text-warning::placeholder {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
  }

  .dark\:before\:via-default-700\/10:before {
    content: var(--tw-content);
    --tw-gradient-to: hsl(var(--nextui-default-700) / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), hsl(var(--nextui-default-700) / .1) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }

  .dark\:after\:bg-content2:after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
  }

  .dark\:data-\[hover\=true\]\:bg-content2[data-hover="true"] {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--nextui-content2) / var(--nextui-content2-opacity, var(--tw-bg-opacity)));
  }

  .dark\:data-\[selected\=true\]\:text-danger-500[data-selected="true"] {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-danger-500) / var(--nextui-danger-500-opacity, var(--tw-text-opacity)));
  }

  .dark\:data-\[selected\=true\]\:text-success[data-selected="true"] {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-success) / var(--nextui-success-opacity, var(--tw-text-opacity)));
  }

  .dark\:data-\[selected\=true\]\:text-warning[data-selected="true"] {
    --tw-text-opacity: 1;
    color: hsl(var(--nextui-warning) / var(--nextui-warning-opacity, var(--tw-text-opacity)));
  }
}

@media (width >= 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:\[--scale-enter\:100\%\] {
    --scale-enter: 100%;
  }

  .sm\:\[--scale-exit\:103\%\] {
    --scale-exit: 103%;
  }

  .sm\:\[--slide-enter\:0px\] {
    --slide-enter: 0px;
  }

  .sm\:\[--slide-exit\:0px\] {
    --slide-exit: 0px;
  }
}

@media (width >= 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/6 {
    width: 16.6667%;
  }
}

.\[\&\:not\(\:first-child\)\:not\(\:last-child\)\]\:rounded-none:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.\[\&\:not\(\:first-child\)\]\:ml-\[calc\(theme\(borderWidth\.medium\)\*-1\)\]:not(:first-child) {
  margin-left: calc(var(--nextui-border-width-medium) * -1);
}

.\[\&\:not\(\:first-of-type\)\:not\(\:last-of-type\)\]\:rounded-none:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.\[\&\:not\(\:first-of-type\)\]\:ml-\[calc\(theme\(borderWidth\.2\)\*-1\)\]:not(:first-of-type) {
  margin-left: -2px;
}

.\[\&\>\*\]\:relative > * {
  position: relative;
}

.\[\&\>svg\]\:max-w-\[theme\(spacing\.unit-8\)\] > svg {
  max-width: var(--nextui-spacing-unit-8);
}

.\[\&\>tr\]\:first\:rounded-lg:first-child > tr {
  border-radius: .5rem;
}

.\[\&\>tr\]\:first\:shadow-small:first-child > tr {
  --tw-shadow: var(--nextui-box-shadow-small);
  --tw-shadow-colored: var(--nextui-box-shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.30fa2792.woff2") format("woff2"), url("Inter-Thin.509b3195.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.baaa147a.woff2") format("woff2"), url("Inter-ThinItalic.305c6c76.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.964a821e.woff2") format("woff2"), url("Inter-ExtraLight.9bd9159b.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.8892db5f.woff2") format("woff2"), url("Inter-ExtraLightItalic.a68f8177.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.c06f5e9d.woff2") format("woff2"), url("Inter-Light.5feaf686.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.9361988e.woff2") format("woff2"), url("Inter-LightItalic.59e166b6.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.449f0e8a.woff2") format("woff2"), url("Inter-Regular.e79139c9.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.26f3dc3f.woff2") format("woff2"), url("Inter-Italic.9092ffa2.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.acc3d50f.woff2") format("woff2"), url("Inter-Medium.cfcc1d28.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.078088d6.woff2") format("woff2"), url("Inter-MediumItalic.4aa3953c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.bdb1c7ad.woff2") format("woff2"), url("Inter-SemiBold.fa7f9547.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.2550cf4d.woff2") format("woff2"), url("Inter-SemiBoldItalic.fb0578b1.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.53ea85ae.woff2") format("woff2"), url("Inter-Bold.d03e3d36.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.2c92d711.woff2") format("woff2"), url("Inter-BoldItalic.4ca4d3bd.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.3eeb4074.woff2") format("woff2"), url("Inter-ExtraBold.0d648a7c.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.1abc120e.woff2") format("woff2"), url("Inter-ExtraBoldItalic.6beaf16d.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.1da350e0.woff2") format("woff2"), url("Inter-Black.0258c5ed.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.f3c01519.woff2") format("woff2"), url("Inter-BlackItalic.2de967a4.woff") format("woff");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: "Regular";
  font-display: swap;
  src: url("Inter-roman.var.b695afbe.woff2") format("woff2 supports variations(gvar)"), url("Inter-roman.var.b695afbe.woff2") format("woff2-variations"), url("Inter-roman.var.b695afbe.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: "Italic";
  font-display: swap;
  src: url("Inter-italic.var.69eb0fe1.woff2") format("woff2 supports variations(gvar)"), url("Inter-italic.var.69eb0fe1.woff2") format("woff2-variations"), url("Inter-italic.var.69eb0fe1.woff2") format("woff2");
}

@font-face {
  font-family: Inter var experimental;
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  src: url("Inter.var.5f2f8893.woff2") format("woff2-variations"), url("Inter.var.5f2f8893.woff2") format("woff2");
}

/*# sourceMappingURL=index.7d964104.css.map */
